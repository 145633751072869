<template>
  <div class="mt-1">
    <b-card>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <b-row class="mb-1">
          <b-col cols="12" md="5" class="d-flex align-items-center justify-content-start mb-1 mb-md-0"> </b-col>
          <b-col cols="12" md="7">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="filter" class="d-inline-block mr-1" cle placeholder="Search..." />
              <b-button variant="primary" @click="addMaterial()">
                <span class="text-nowrap">Add Material</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-table :outlined="true" :fields="fields" responsive="sm" class="table table-sm" :items="filteredMaterials">
          <template #cell(name)="data">
            <div v-if="!data.item.editable">{{ data.value }}</div>
            <div v-else>
              <b-form-input autofocus size="sm" id="name" type="text" v-model="data.item.name"></b-form-input>
            </div>
          </template>

          <template #cell(status)="data">
            <span v-if="data.value == 'active'">
              <b-badge variant="success" class="w-100 p-50"> <i class="fas fa-check"></i> Activated </b-badge>
            </span>
            <span v-if="data.value == 'pending'" @click="activeMaterial(data.item.id)" style="cursor: pointer;" v-b-tooltip.hover.top="'Click to activate!'">
              <b-badge variant="warning" class="w-100 p-50 hoverable"> <i class="fa-solid fa-hourglass-half"></i> Pending </b-badge>
            </span>
          </template>

          <template #cell(actions)="data">
            <div>
              <b-button variant="primary" class="mr-25 p-50" @click="editMaterial(data.item.id)">
                <i class="fa-solid fa-pen-to-square mr-25"></i>
                <span> Edit</span>
              </b-button>

              <b-button variant="primary" @click="deleteMaterial(data.item.id)" class="p-50">
                <i class="fa-solid fa-trash mr-25"></i>
                <span> Delete</span>
              </b-button>
            </div>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import vSelect from 'vue-select';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
  },

  setup() {
    const Material_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(Material_APP_STORE_MODULE_NAME)) store.registerModule(Material_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Material_APP_STORE_MODULE_NAME)) store.unregisterModule(Material_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      materials: [],
      filter: null,
      loading: true,
      fields: [
        { key: 'name', sortable: true, class: 'text-left', thStyle: 'width:70%' },
        { key: 'status', sortable: false, class: 'text-center', thStyle: 'width:10%' },
        { key: 'actions', sortable: false, class: 'text-center', thStyle: 'width:20%' },
      ],
      tempName: null,
      showAllAdditionals: false,
    };
  },

  watch: {},

  methods: {
    getMaterials() {
      this.loading = true;
      store
        .dispatch('settings/getAllMaterials')
        .then((res) => {
          this.materials = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$router.push({ name: 'settings-dashboard' });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    editMaterial(id) {
      this.$router.push({ name: 'settings-material-edit', params: { id: id } });
    },

    deleteMaterial(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this material?', {
          size: 'sm',
          title: 'Confirm Deletion',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            const material = this.materials.find((met) => met.id === id);

            if (material) {
              store
                .dispatch('settings/deleteMaterial', material)
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Material Delete Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  this.getMaterials();
                })
                .catch((error) => {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'An error occurred',
                      text: 'Please try again later or contact support.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  });
                });
            }
          }
        });
    },

    activeMaterial(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to activate this material?', {
          size: 'sm',
          title: 'Confirm Activation',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            const material = this.materials.find((met) => met.id === id);

            if (material) {
              store
                .dispatch('settings/activeMaterial', material)
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Material Activate Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  this.getMaterials();
                })
                .catch((error) => {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'An error occurred',
                      text: 'Please try again later or contact support.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  });
                });
            }
          }
        });
    },

    addMaterial() {
      this.$router.push({ name: 'settings-material-add' });
    },
  },

  created() {
    this.getMaterials();
  },

  computed: {
    filteredMaterials() {
      if (!this.filter) {
        return this.materials;
      } else {
        this.materials = this.materials.filter((met) => met.id !== undefined);

        this.materials.forEach((met) => {
          met.editable = false;
        });

        const searchTerm = this.filter.toLowerCase();
        return this.materials.filter((met) => met.name.toLowerCase().includes(searchTerm));
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.hoverable:hover {
  background-color: lightgray; /* veya farklı bir renk */
}
</style>
